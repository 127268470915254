@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@layer base {
  .theme-opening-story {
    color: rgb(50, 55, 242);
  }
}

@font-face {
  font-family: Hiragino Sans;
  src: url('./assets/fonts/HiraginoInterface.ttc');
}

@font-face {
  font-family: VDL-LineGR;
  src: url('./assets/fonts/VDL-LineGR.otf');
}

@font-face {
  font-family: Alibaba Sans Black;
  src: url('./assets/fonts/AlibabaSans-Black.ttf');
}

@font-face {
  font-family: Source Han Sans;
  src: url('./assets/fonts/SourceHanSansJP-Medium.otf');
}

@font-face {
  font-family: FOT-KurokaneStd;
  src: url('./assets/fonts/FOT-KurokaneStd-EB.otf');
}

.styled-scroll::-webkit-scrollbar {
  width: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5.5px;
  background-color: #fff;
}

.styled-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background-image: url('./assets/img/termofservice/bg-scroll.png');
  background-repeat: no-repeat; */
  background-image: linear-gradient(0deg,
      hsl(204deg 54% 82%) 0%,
      hsl(205deg 52% 79%) 9%,
      hsl(206deg 51% 77%) 18%,
      hsl(207deg 49% 74%) 27%,
      hsl(208deg 48% 73%) 36%,
      hsl(208deg 49% 74%) 46%,
      hsl(208deg 50% 74%) 55%,
      hsl(208deg 51% 75%) 64%,
      hsl(207deg 52% 76%) 73%,
      hsl(206deg 53% 78%) 82%,
      hsl(205deg 53% 80%) 91%,
      hsl(204deg 54% 82%) 100%);
  border: solid 1px #dfebf6;
  -moz-box-shadow: inset -1px -2px 1px #00000030;
  -webkit-box-shadow: inset -1px -2px 1px #00000030;
  box-shadow: inset -1px -2px 1px #00000030;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.carousel-wrapper .carousel.carousel-slider {
  overflow: unset;
}

.carousel-wrapper .carousel .slide {
  border: 0;
  box-shadow: 0 35px 35px rgba(0, 0, 0, 0.25);
}

.carousel-wrapper .carousel .slide img {
  filter: drop-shadow(0px 2px #1e429e);
  overflow: hidden;
}

.carousel-wrapper .carousel .control-dots {
  transform: skew(0, 1deg);
  bottom: -24px;
  text-align: right;
}

.carousel-wrapper .carousel .control-dots .dot {
  width: 14px;
  height: 4px;
  background-color: #fff;
  border-radius: unset;
  opacity: 1;
  box-shadow: 2px 2px #1e429e;
  margin-left: 8px;
  margin-right: 2px;
}

.carousel-wrapper .carousel .control-dots .dot.selected {
  background-color: #e8ba47;
}

.chat-item {
  position: absolute;
  top: 18px;
  left: -18px;
  clip-path: polygon(100% 0, 0 49%, 100% 100%);
  width: 18px;
  height: 14px;
  background-color: #fff;
  z-index: 0;
}

.chat-item::before {
  position: absolute;
  content: '';
  top: 0.5px;
  left: -0.5px;
  width: 20px;
  height: 13px;
  background: #325282;
  clip-path: polygon(100% 0, 0 49%, 100% 100%);
}

.chat-received-item {
  position: absolute;
  top: 10px;
  right: -18px;
  clip-path: polygon(100% 0, 0 49%, 100% 100%);
  width: 18px;
  height: 14px;
  background-color: #325282;
  z-index: 0;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

.chat-received-item::before {
  position: absolute;
  content: '';
  top: 0.5px;
  left: -0.5px;
  width: 20px;
  height: 13px;
  background: #ffffff;
  clip-path: polygon(100% 0, 0 49%, 100% 100%);
}

.mana-wrapper .carousel,
.mana-wrapper .slider-wrapper,
.mana-wrapper .slider-wrapper .slider {
  height: 100%;
}

.mana-wrapper .carousel.carousel-slider {
  overflow: unset;
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:focus-visible {
  outline: none;
}

.shop-bg-border {
  background: linear-gradient(180deg, #dff2ff 0%, #dbecff 58.85%, #9dc3fc 100%);
}

.shop-bg-border-pink {
  background: linear-gradient(360deg, #ffadd4 4.22%, #fffafe 58.6%, #ffadd4 100%);
}

.reward-page main .content{
  background: #98a5d6;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.reward-page .title-reward {
  padding: 0 70px;
}

.reward-page .period-reward {
  position: absolute;
  width: 100%;
  padding: 0 12%;
  top: 23%;
  font-size: 70%;
}

.bg-dark-600 {
  background-color: #666666;
}

.animation-fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.live-arrow-top {
  @media screen and (max-height: 667px) {
    top: 32%;
  }

  @media screen and (min-height: 668px) and (max-height: 740px) {
    top: 34%;
  }

  @media screen and (min-height: 741px) and (max-height: 1000px) {
    top: 35%;
  }

  @media screen and (min-height: 1001px) {
    top: 40%;
  }
}

.bg-time-item {
  background-color: #ff59a3;
}

.bg-normal-item {
  background-color: #ffa359;
}

.bg-comment {
  background: rgba(0, 0, 0, 0.6);
}

.bg-joined-user {
  background: rgba(76, 135, 253, 0.7);
}

.style-list ul, ol{
  list-style: auto !important;
}

.style-list a{
  text-decoration: underline !important;
}
