.cd__main {
  display: block !important;
}
.card-carousel {
  position: relative;
  margin: 0 auto 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  perspective-origin: top;
  padding-top: 40px;
}
.card-carousel .button-spin {
  position: absolute;
  top: 50%;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Open Sans';
  font-weight: 800;
  padding: 10px 16px;
  /* text-shadow: 1px 1px 4px rgba(0, 54, 90, 0.5); */
}
.card-carousel .button-spin:hover {
  /* box-shadow: 0px 4px 4px 4px rgba(0, 54, 90, 0.15); */
}
.card-carousel .button-spin:active {
  box-shadow: none;
}
.card-carousel .button-spin.counterclockwise {
  left: 0;
}
.card-carousel .button-spin.clockwise {
  right: 0;
}
.card-carousel .inner-carousel {
  position: relative;
  width: 110px;
  margin: 0 auto;
  top: 80px;
  transform-style: preserve-3d;
}
.card-carousel .inner-carousel > div {
  position: absolute;
  padding: 10px;
  width: 225px;
  height: 700px;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  left: -60px;
  transform: translate(-50%, -50%);
}

.card-carousel .inner-carousel .img-slide {
  overflow: visible;
}

.icon-love {
  width: 30px !important;
  position: absolute;
  left: 69%;
  top: 19.5%;
}

.first-slide {
  scale: 1.5; 
  transform: translateY(127px) translateZ(117px) !important;
}

.first-slide .img-slide {
  height: auto;   
}

.card-carousel .inner-carousel > div.front {
  top: 20px;
}
.card-carousel .inner-carousel > div.front a:hover,
.card-carousel .inner-carousel > div.front a:focus {
  border: 2px solid #48cfad;
  padding: 5px 0;
}
.card-carousel .inner-carousel > div.front a:active {
  box-shadow: none;
}
.test {
  top: -100px;
  margin-right: 10px;
}

@media screen and (max-height: 759px) {
  .carousel-standard-wrapper {
    bottom: 0 !important;
  }
}
