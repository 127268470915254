/* .w-screen {
    font-size: 16px;
    width: 100% !important;
}

.hAePdY {
    background: none !important;
} */

.d-block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}

.information-order {
    margin-top: 50px;
    background-color: #1a75bb;
    padding: 20px 0 60px 0;
}

.ttl_area {
    width: 100%;
}

.ttl_inn {
    width: 30%;
    margin: 0 auto;
}

.ttl_inn h2 {
    background-color: #76ACD6;
    color: #FFFFFF;
    position: relative;
    top: -44px;
    text-align: center;
    padding: 10px;
    margin: 0;
    font-size: 16px;
}

.text-information-order {
    background-color: #FFFFFF !important;
    color: #1a75bb;
    background-image: url('/src/assets/img/common/ico_info_01.png') !important;
    background-repeat: no-repeat;
    background-position: 14px center;
    background-size: 17px;
    border: 0px;
    border-radius: 20px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    padding: 3px 27px 3px 46px;
    margin: 0 0 36px 0;
    appearance: none;
    font-size: 12pt;
}

.ga_inner {
    max-width: 900px;
    margin: 0 auto;
}

.btoo {
    text-align: center;
}

.ga_open01 ul {
    display: table;
    margin: 0 auto;
    padding: 10px 11px 20px 11px;
    width: 50%;
}

.ga_open01 ul li:first-child {
    padding-right: 0;
}

.ga_open01 ul li {
    display: table-cell;
    font-size: 12pt;
    width: 40%;
    text-align: center;
}

.ga_open01 ul li div:first-child {
    background-color: #76ACD6;
    color: #FFFFFF;
    box-shadow: 5px 5px 5px rgba(52, 65, 150, 0.27);
    padding: 10px 0;
}

.ga_open01 ul li div:last-child {
    background-color: #FFFFFF;
    color: #007495;
    box-shadow: 5px 5px 5px rgba(52, 65, 150, 0.27);
    padding: 10px 0;
}

.body-order {
    padding-top: 20px;
    background-color: #fff;
}

.pgn_area {
    margin-top: 20px;
    width: 100%;
}

.swiper_area {
    padding: 20px 0 0 0;
}

.swiper-wrapper img {
    width: 100%;
    height: 100%;
    display: block;
}

.swiper-wrapper span {
    background-color: #76ACD6;
    color: #FFFFFF;
}

.swiper-wrapper span {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    left: 0;
}

.swiper-slide {
    border-color: #76ACD6;
}

.swiper-slide {
    cursor: pointer;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    border-style: solid;
    border-width: 1px;
}

.icon-webmoney {
    display: block;
    width: 200px;
    height: 200px;
    position: relative;
    border: 1px solid #76ACD6;
    padding-bottom: 36px;
    background: #fff;
    display: flex;
    justify-content: center;
}

.img-webmoney {
    height: 100%;
    width: auto !important;
}

.swiper-wrapper {
    display: flex;
    justify-content: center;
}

.content {
    display: block;
    padding-top: 1px;
    animation-duration: 0s;
    animation-name: SlideDown;
}

.content:target {
    display: block;
    animation-duration: 0.3s;
    animation-name: SlideDown;
}

section.select_form {
    border-color: #76ACD6;
    background-color: #E1ECEF;
    margin-top: 60px;
    display: block;
}

.ttl_area02 {
    width: 100%;
}

.ttl_inn02 {
    width: 30%;
}

.ttl_inn02 h3 {
    background-color: #76ACD6;
    color: #FFFFFF;
}

.ttl_inn02 h3 {
    position: relative;
    top: -24px;
    text-align: center;
    padding: 10px;
}

.select_form_inn {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 8px 40px 8px;
}

.ip_10 {
    margin-bottom: 20px;
}

.ip_10 .mesbox {
    background-color: #f1f6f7;
    color: #828c9a;
    width: 100%;
    height: auto;
    border: 2px solid #cddce0;
    border-radius: 4px;
    padding: 8px 12px;
    vertical-align: top;
    text-align: left;
}

.sbt_area {
    padding: 30px 0 0 0;
    text-align: center;
}

.un_sbt_deco {
    background-color: #FFFFFF;
    border-color: #1765a0;
    color: #007495;
    border-style: solid;
    border-width: 3px;
    border-radius: 46px;
    cursor: pointer;
    font-weight: bold;
    height: 46px;
    margin: 0 7px;
    max-width: 130px;
    width: 100%;
}

.sbt_deco {
    background-color: #1765a0 !important;
    color: #FFFFFF;
    border: 0;
    border-radius: 46px;
    cursor: pointer;
    font-weight: bold;
    height: 46px;
    margin: 0 7px;
    max-width: 130px;
    width: 100%;
}

.ga_table {
    width: 100%;
    box-shadow: 5px 5px 5px rgba(52, 65, 150, 0.27);
}

.ga_table tr:last-child td:first-child {
    border-left: none;
    border-bottom: none;
}

.ga_table tr:first-child td:first-child {
    border-top: none;
}

.ga_table td:first-child {
    background-color: #76acd6;
    color: #FFFFFF;
    width: 20%;
    padding: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.ga_table td {
    background-color: #FFFFFF;
    width: 80%;
    padding: 10px;
    vertical-align: middle;
    color: #636363;
    font-size: 16px;
    text-align: left;
}

.ga_hide {
    transition: height 0.2s ease-in-out 0s;
}

@media screen and (max-width: 1400px) {
    .swiper-wrapper span {
        font-size: 10pt;
        line-height: 1.2;
    }
}

@media screen and (max-width: 1200px) {
    .swiper-wrapper span {
        font-size: 9pt;
    }
}

@media screen and (max-width: 768px) {
    .ttl_inn {
        width: 80%;
    }

    .ttl_inn02 {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .ttl_inn {
        width: 100%;
    }

    .ga_open01 ul {
        width: 100%;
    }
}
